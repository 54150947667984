// eslint-disable-next-line
/* global Component, uuidv4 */
// eslint-disable-next-line
class itemStockComponent extends itemProxyComponent {

    static name() {
        return "itemStockComponent";
    }

    getWatch() {
        return  {
            "item": function () {
                this.favorite = this.$store.getters.getFavorites.indexOf(this.item.Code) != -1;
            },
            "Qty":function (){
                if(this.controlStock)
                    if(Number.isInteger(this.item.Qty) && this.item.Qty <= this.Qty) {
                        this.Qty = this.item.Qty;
                        this.alertShowMessage("Maximum item quantity reached");
                    }
            }
        };
    }

    static componentName() {
        return "itemStockComponent";
    }

    getTemplate(){
        return `<div class="item-detail">
                <div class="col-12">
                   <div class="container-fluid">
                    <div class="row" v-if="item">
                        <div class="col-md-4 col-sm-12" >
                             <itemSliderComponent :useZoom="imgUseZoom" :slideBigImage="imgSlideBigImage" :item="item" :stockFlag="showStock" :container="true" :showCarusel="imgShowCarusel"></itemSliderComponent>
                        </div>
                        <div class="col-md-8 col-sm-12">
      			                <p class="product-title">{{item.Name}}</p>
                            <div class="product-description">
                                <p class="subtitle">{{tr("Description")}}</p>
                                <p class="product-comment" v-if="item.Comment">{{item.Comment}}</p>
                                <p v-else>{{tr("Article without description.")}}</p>
                            </div>
                            <div class="product-details">
                                <p class="product-code">{{tr("ECOMITEMCODE")}} {{codeToShow}}</p>
                                <p class="product-code" v-if="$store.getters.showStock">Stock {{item.Qty - item.Reserved}}</p>
                                <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                    <p class="item-price" v-if="!loadingPrice">
                                        <priceComponent :item="item"></priceComponent>
                                    </p>
                                </template>
                                <template v-if="showClassification">
                                    <p class="subtitle">{{tr('Classifications')}}</p>
                                    <ol class="flex-column">
                                         <template v-for="cat in classificationList">
                                            <li :key="'item-cat-'+cat.name" @click="filterByCategory(cat)">
                                                <router-link :to="'/items?category=' + encodeURI(cat.code+'__'+cat.name)">
                                                    {{capitalize(cat.name)}}
                                                </router-link>
                                            </li>
                                        </template>
                                    </ol>
                                </template>
                            </div>
                            <div class="row product-shops">
                                <template v-if="alertMsg">
                                    <div class="col-12 alert alert-danger" role="alert">{{tr(alertMsg)}}</div>
                                </template>
                                <div class="input-group qty-input col-4 col-sm-6 col-xl-3 col-md-5 pr-0 pr-xl-4">
                                    <template v-if="controlStock">
                                        <template v-if="getMaxStockByCode(item.Code)">
                                            <div class="input-group-prepend d-none d-sm-flex" >
                                                <button class="btn btn-outline-secondary" type="button" @click.stop="itemQtyDecrement"><i class="icon fas fa-minus"></i></button>
                                            </div>
                                            <input :ref="'carCant-'+item.Code" v-model="Qty" min='1' :max="getMaxStockByCode(item.Code)" class="form-control inputch" type="text" v-on:change.stop="onChangeQty">
                                            <div class="input-group-append d-none d-sm-flex">
                                                <button class="btn btn-outline-secondary" type="button" @click.stop="itemQtyIncrement"><i class="icon fas fa-plus"></i></button>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <input :ref="'carCant-'+item.Code" v-model="Qty" min='0' :max="0" class="form-control inputch" type="text" disabled>
                                            <small id="withOutStockHelpBlock" class="form-text text-muted">
                                                {{tr("Without Stock")}}
                                            </small>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <div class="input-group-prepend d-none d-sm-flex" >
                                            <button class="btn btn-outline-secondary" type="button" @click.stop="itemQtyDecrement"><i class="icon fas fa-minus"></i></button>
                                        </div>
                                        <input :ref="'carCant-'+item.Code" v-model="Qty" min='1' :max="getMaxStockByCode(item.Code)" class="form-control inputch" type="text" v-on:change.stop="onChangeQty">
                                        <div class="input-group-append d-none d-sm-flex">
                                            <button class="btn btn-outline-secondary" type="button" @click.stop="itemQtyIncrement"><i class="icon fas fa-plus"></i></button>
                                        </div>
                                    </template>
                                </div>
                                <span class="cart-button col-8 col-sm-6 col-xl-12 col-md-4 pl-sm-0 pl-sm-3 text-left mt-0 mt-xl-2">
                                    <button class="btn secondary-btn px-1 px-sm-3 px-xl-5" :disabled="getMaxStockByCode(item.Code) < 1" type="button" @click.stop="canShopItem ? ItemComponentAddToCar(item) : null"><span class="icon"><i class="fa-icon fas fa-cart-plus"></i></span> {{tr("Load to Cart")}}</button>
                                </span>
                                <div class="social-buttons col-12 text-center text-md-left mt-4 p-0 pl-md-3">
                                    <a v-if="enableFB" class="social-button mr-1" title="Facebook"  target="_blank" rel="nofollow"  :href="'https://www.facebook.com/sharer.php?u='+getItemUrl()" >
                                        <i class="icon fab fa-facebook-square"></i>
                                    </a>
                                    <a v-if="enableTW" class="social-button" title="Twitter" target="_blank" rel="nofollow" :href="'https://twitter.com/intent/tweet?url='+getItemUrl()">
                                        <i class="icon fab fa-twitter-square"></i>
                                    </a>
                                    <a v-if="enableIG" class="social-button" title="Instagram" target="_blank" rel="nofollow" :href="linkIG" >
                                        <i class="icon fab fa-instagram-square"></i>
                                    </a>
                                     <a v-if="enableLK" class="social-button" title="LinkedIn" target="_blank" rel="nofollow" :href="linkLK" >
                                        <i class="icon fab fa-linkedin"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div v-if="relatedItems.length>0" class="related-items w-100 px-3">
                            <div class="mt-3">
                                <homeItemCardComponent :section="{internalId:item.Code}" :items="relatedItems" :title="tr('Related Items')" :titleCenter="true" :fullScreen="true"/>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
			</div>`;
    }
}

itemStockComponent.registerComponent();
